.notFoundContainer {
  height: auto;
  width: 100%;
  max-width: 816px;
  margin: 100px auto;
  text-align: center;
  background-color: #fff;
  border-radius: 24px;
  padding: 50px;

  button {
    width: 325px;
    margin: 20px 0;
  }

  .notFoundIcon {
    width: 10%;
  }

  .notFoundDesc {
    margin: 0 auto;
  }
}
