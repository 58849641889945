@import "@jsluna/foundation/scss/main";

.management_portal_confirmation_modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &_open {
    min-height: 20rem;
  }

  .selectionList {
    min-height: 5rem;
    max-height: 15rem;
    overflow-y: auto;
    padding: 0.4rem;
    margin-bottom: 1.5rem;
  }

  .overlayModel {
    min-width: 57rem;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 5px 0;
  }
}
