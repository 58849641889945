.logoAnimate {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(120deg);
  }
}
