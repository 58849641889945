@import "@jsluna/foundation/scss/main";

.management-portal-status-indicator {
  display: flex;
  border-radius: 4px;
  color: $ln-color-white;
  align-items: center;
  justify-content: space-evenly;
  padding-top: $ln-space;
  padding-bottom: $ln-space;

  &__alpha {
    background-color: $ln-color-alpha;
  }
  &__disabled {
    background-color: $ln-color-disabled;
  }
  &__error {
    background-color: $ln-color-error;
  }
  &__warning {
    background-color: $ln-color-warning;
  }
  &__success {
    background-color: $ln-color-success;
  }
  &__info {
    background-color: $ln-color-info;
  }
}
