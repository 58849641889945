@import "@jsluna/foundation/scss/main";

.campaign-upload-dashboard {
  .campaign-upload-dashboard-results {
    tbody tr {
      height: calc(
        var(--ds-space-spacing-x6) + var(--ds-space-spacing-x2)
      ); // Match the height of download buttons, plus the normal padding, when there are none
    }
  }
}
