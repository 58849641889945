@import "@jsluna/foundation/scss/main";

.management_portal_page_header {
  > svg {
    margin-right: $ln-space-x2;
    font-size: 24px;
  }
  display: flex;
  align-items: center;
}
