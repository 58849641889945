.management-portal-dropdown {
  font-weight: var(--ds-font-stack-button-font-weight);
  font-family: var(--ds-font-stack-button-font-family);
  font-size: var(--ds-font-stack-button-font-size);

  & &_button {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  & &_list {
    width: 100% !important;
    cursor: pointer;
  }
}
