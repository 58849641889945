@import "@jsluna/foundation/scss/main";

.campaignsContainer {
  .campaigns-select-container {
    justify-content: center;
  }

  .clear-filter-button {
    width: 100%;
  }

  .filter-container {
    margin-top: -1rem; //Not a good way of removing extra top spacing
  }
}
