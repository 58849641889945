.podEditorForm {
  select,
  input,
  button {
    border-radius: 5px;
  }

  select,
  input {
    border-color: #7373734d !important;
  }
}
.podPreview {
  position: relative;
  width: 18rem;
  height: 27.25rem;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0px 0px 20px #888888;
}

.podEditorLoading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.previewTitle {
  padding-top: 1.5rem;
  text-align: left;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 1.563rem;
  font-weight: bold;
}

.previewSubText {
  padding: 1.25rem 1.25rem 1rem;
  font-size: 1rem;
  text-align: left;
}

.previewOnlyImagedLayout {
  padding-bottom: 0px;
  position: absolute;
  bottom: 3.0625rem;
  top: auto;
  left: 0px;
}

.previewTitledLayout {
  padding-bottom: 2.625rem;
}

.previewFooter {
  position: absolute;
  display: grid;
  grid-auto-flow: column;
  justify-content: initial;
  align-items: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3.9375rem;
  background: rgb(255, 255, 255);
}

.previewMultiFooter {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3.9375rem;
  background: rgb(255, 255, 255);
}

.podColorsGroup {
  .ln-c-input-group,
  .ln-c-input-group--has-action {
    border-radius: 5px;
    border-color: #7373734d;
  }
}
