.image-description {
  display: flex;
  align-items: center;
  gap: 10px;

  .small-image {
    width: 30px;
    height: auto;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }

    .sub-header {
      font-size: 14px;
      color: gray;
      margin: 0;
    }
  }
}
