@import "@jsluna/foundation/scss/main";

.management_portal_inline_group {
  display: flex;
  align-items: flex-start;
  gap: $ln-space-x2;

  &_align_end {
    justify-content: flex-end;
  }

  &_align_start {
    justify-content: flex-start;
  }
}
