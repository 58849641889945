@import "@jsluna/foundation/scss/main";

.management_portal_date_time_field {
  @include ln-text-input;

  // The default padding makes the buttons look weird
  padding-right: 0;

  // We want most of the properties on this element, but the padding needs to go on the input or it looks strange on focus
  padding-left: 0;
  .rw-widget-input {
    // Override the values that it adds, because luna sets ideal values on the parent
    color: unset;
    padding: unset;
    background-color: unset;

    // Add the padding back on the input
    padding-left: $ln-space-x2;
  }

  .rw-select-bordered {
    // Without this, the hover background on the buttons overflows the box shadow that luna gives the input on hover
    background-clip: content-box;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  @include ln-text-input-validation-states;
}
