.padding-left-none {
  padding-left: 0 !important;
}

.ln-c-accordion {
  background-color: #efefef !important;

  &.add-task-accordion-item {
    text-transform: capitalize !important;
  }
}

.marketing-pagination {
  .ln-c-pagination__item.is-current .ln-c-pagination__link {
    background-color: #e55000 !important;
    color: #ffff;
  }
}

.custom-checkbox {
  margin: 0 !important;
}

.ui.button.dropdown .menu {
  right: 0 !important;
  left: auto !important;
}

.custom-dropdown {
  box-shadow: 0 0 0 var(--ds-modifier-focus-ring-focus-width) transparent !important;
  border: var(--ds-modifier-border-interactive-width) solid
    var(--ds-mode-default-border-interactive) !important;
  border-radius: var(--ds-modifier-border-radius) !important;
  color: var(--ds-mode-default-base) !important;
}

.custom-datepicker {
  width: "100%" !important;
  background-color: "transparent" !important;
  box-shadow: "2px #737373" !important;
  font-family: "sans-serif" !important;
  border: "1px solid #737373" !important;
  border-radius: "4px" !important;
  color: "#737373" !important;
}

.status-container {
  display: flex;
  flex-direction: row;
}

.status-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}

.iphone-frame {
  /* The device with borders */

  .iphone {
    position: relative;
    width: 230px;
    height: 500px;
    margin: auto;
    border: 5px #28282b solid;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-radius: 35px;
  }

  .floatalways {
    position: fixed;
    top: 16.5rem !important;
    right: 5.5rem !important;
  }

  /* The horizontal line on the top of the device */

  .iphone:before {
    content: "";
    display: block;
    width: 75px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }

  /* The screen (or content) of the device */

  .iphone-content {
    width: 100%;
    height: 100%;
    overflow: overlay;
    background: #f2f2f2;
    border-radius: inherit;
    padding: 35px 7px 5px 7px;
  }

  .task-options-container {
    height: 100px;
    width: 100%;
    background-color: #ffff;
    border-radius: 24px;

    .task-header {
      text-align: center;
      font-weight: bold;
    }
  }

  .task {
    display: flex;
    width: 100%;
    background-color: #ffff;
    border-radius: 24px;
    align-items: center;
    justify-content: space-around;

    .task-container {
      width: 70%;
      margin-left: 5px;
    }

    .custom-title1 {
      margin-bottom: unset !important;
      color: #3f384c;
      font-size: 15px;
    }

    .custom-title2 {
      margin-bottom: 5px !important;
      font-weight: bold;
      color: #3f384c;
      font-size: 14px;
    }

    .img-container {
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #f2f2f2;
      justify-content: center;
      align-items: center;

      .lock-img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .custom-label {
    display: flex;
    justify-content: center;
    color: #8223fa;
  }
}

.custom-stepper {
  button.active {
    background-color: #f06c02;
  }

  button.completed {
    background-color: green;
  }

  span {
    font-weight: bold;
  }
}

.eyeIcon {
  width: 20px;
}

.custom-modal {
  .ln-c-modal__body-wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.ug-csv-browse-info {
  position: relative;
  margin-left: 200px;
  bottom: 40px;
  opacity: 0.7;
  font-size: smaller;
}

.ug-csv-file-label {
  margin: 10px;

  button {
    position: relative;
    margin-left: 20px;
  }
}

.megapod-preview {
  position: relative;
  width: 18rem;
  height: 27.25rem;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: rgb(136 136 136) 0px 0px 20px;
}

.megapod-header {
  padding-top: 1.5rem;
  text-align: left;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 1.563rem;
  font-weight: bold;
}

.megapod-sub-header {
  padding: 1.25rem 1.25rem 1rem;
  font-size: 1rem;
  text-align: left;
}

.megapod-img {
  display: flex;
  align-items: center;
  width: 100%;
  background: rgb(255, 255, 255);
}

.megapod-footer-img {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3.9375rem;
  padding: 5px;
  background: rgb(255, 255, 255);
}

.tooltip-date {
  position: relative;
  // display: inline-block;
}

.tooltip-date .tooltiptext-date {
  visibility: hidden;
  width: 60%;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 0px 0;
  position: absolute;
  right: 0px;
  bottom: 55px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip-date .tooltiptext-date::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-date:hover .tooltiptext-date {
  visibility: visible;
  opacity: 1;
}

.custom-card {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 1rem 0 1rem 0;
}

.highlighted-text {
  color: #e55000;
  font-weight: bold;
}
