@import "@jsluna/foundation/scss/main";

.campaign-editor {
  .campaign-editor-header {
    margin-bottom: $ln-space-x3;
  }
  .campaign-editor-barcode {
    margin-bottom: $ln-space;
  }
}
