.homepagePodList__table {
  padding-top: "4.5rem";

  th {
    padding: 0 0 1rem 1rem;
  }
}

.homepagePodList__container {
  font-family: Roboto-Regular;
}

.fontGreen {
  background-color: green;
  border-radius: 3px;
  color: #fff;
  padding: 5px;
}

.fontRed {
  background-color: rgb(228, 51, 53);
  border-radius: 3px;
  color: #fff;
  padding: 5px;
}
