@import "@jsluna/foundation/scss/main";
.management-portal-product-selector {
  .selector-header {
    display: flex;
    justify-content: space-between;
  }

  .result-container {
    height: 30rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .empty-message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 30rem;
      align-items: center;
    }
    .loading-indicator {
      display: flex;
      flex-direction: column;
      height: 2rem;
      width: 11rem !important;
      margin: 14rem auto;
    }
  }
}
