.homepagepod-image-preview-modal {
  .ln-c-modal__body-wrapper {
    width: 30% !important;

    @media (max-width: 960px) {
      width: 50%;
    }

    @media (max-width: 1250px) {
      width: 40%;
    }

    p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.homepagepod-label {
  &:active,
  &:hover,
  &:focus {
    color: #f06c00 !important;
    text-decoration: underline;
  }

  cursor: pointer;
  margin-bottom: 0px;
}
